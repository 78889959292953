@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/figtree/400.css";
@import "@fontsource/figtree/600.css";

* {
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: "Figtree", sans-serif;
}

html,
body,
#root {
  height: 100%;
  overscroll-behavior: none;
}

.btn-dark-default {
  @apply bg-harmony-button-solid-neutral-bg text-harmony-button-solid-neutral-fg rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-2 w-full font-semibold text-lg;
}

.btn-light-border {
  @apply bg-white text-harmony-text-base border-harmony-button-outline-neutral-border border rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-1 w-full font-semibold text-lg;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1944;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .StripeElement {
  @apply rounded-lg border border-slate-400 h-[50px] p-[16px] w-full hover:outline-[#a1c3de] bg-white;
} */

.loader {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #fff;
  animation: prixClipFix 2s linear infinite;
  @apply dark:border-slate-800;
}

.loader-white {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader-white::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #fff;
  animation: prixClipFix 2s linear infinite;
  @apply dark:border-slate-50;
  border-color: #fff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loader-blue {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #2a2b49;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.container {
  background: #fff;
  padding: 15px;
  width: 400px;
}

.header {
  text-align: center;
  color: #000;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.item-name {
  font-weight: 300;
}

.item-price {
  margin-left: 0.5em;
}

.total {
  width: 100%;
  color: #000;
  margin-top: 5px;
}
